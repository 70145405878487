.helper {
  --reactour-accent: #5cb7b7;
  line-height: 1.3;
  color: #2d2323;
}

#___reactour > div:first-child {
    color: #000;
    opacity: 0.7;
    /* z-index: 99999; */
}