.de-lobby-2023 {
    width: 100%;
    max-width: 1900px;
    margin-bottom: 0!important;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center
}

.de-lobby-2023 .event-logo {
    width: 60%;
    max-width: 300px;
    margin-top: 2rem;
    margin-left: 1rem
}

@media only screen and (min-width: 768px) {
    .de-lobby-2023 .event-logo {
        margin-left:48px
    }
}

@media only screen and (min-width: 1024px) {
    .de-lobby-2023 .event-logo {
        position:absolute;
        max-width: 450px;
        top: 2rem;
        left: 0
    }
}

.noclick:hover {
    cursor: default;
}

.de-lobby-2023 .de-lobby-navigation {
    display: grid;
    gap: 0;
    align-content: start;
    width: 100%;
    grid-template-columns: 1fr 1fr
}

.de-lobby-2023 .de-lobby-navigation #lobby-item-1,.de-lobby-2023 .de-lobby-navigation #lobby-item-2 {
    grid-column-start: 1;
    grid-column-end: 3
}

.de-lobby-2023 .de-lobby-navigation a:hover {
    text-decoration: none
}

.de-lobby-2023 .de-lobby-navigation a#lobby-item-1 {
    padding: 2.7rem 6.1rem 1rem 4.3rem;
    transition: box-shadow .3s
}

.de-lobby-2023 .de-lobby-navigation a#lobby-item-1:hover {
    text-decoration: none;
    box-shadow: inset 0 0 10px 2px rgba(0,0,0,.47)
}

.de-lobby-2023 .de-lobby-navigation #lobby-item-2 {
    text-align: center;
    padding-top: .5rem
}

.de-lobby-2023 .de-lobby-navigation #lobby-item-2 a {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    transition: box-shadow .3s
}

.de-lobby-2023 .de-lobby-navigation #lobby-item-2 a:hover {
    text-decoration: none;
    box-shadow: inset 0 0 10px 2px rgba(0,0,0,.47)
}

.de-lobby-2023 .de-lobby-navigation .lobby-item-inner {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column
}

.de-lobby-2023 .de-lobby-navigation .lobby-item-inner .lobby-nav-title {
    text-align: center;
    color: #80da00
}

@media only screen and (min-width: 768px) {
    .de-lobby-2023 .de-lobby-navigation {
        grid-template-columns:1fr 1fr 1fr 1fr;
        grid-template-rows: auto auto auto auto;
        align-content: start;
        max-width: 100%;
        margin-top: 4rem
    }

    .de-lobby-2023 .de-lobby-navigation .lobby-item-parent {
        grid-column: 1 / -1; /* Span full width */
        background-color: #f2f2f2;
        padding: 20px;
        text-align: center;
    }

    .de-lobby-2023 .de-lobby-navigation #lobby-item-3 {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 3;
        grid-column-end: 5
    }

    .de-lobby-2023 .de-lobby-navigation #lobby-item-4 {
        grid-row-start: 2;
        grid-column-start: 1;
        grid-column-end: 2
    }

    .de-lobby-2023 .de-lobby-navigation #lobby-item-5 {
        grid-row-start: 2;
        grid-column-start: 2;
        grid-column-end: 3
    }

    .de-lobby-2023 .de-lobby-navigation #lobby-item-6 {
        grid-row-start: 2;
        grid-column-start: 3;
        grid-column-end: 4
    }

    .de-lobby-2023 .de-lobby-navigation #lobby-item-7 {
        grid-row-start: 3;
        grid-column-start: 3;
        grid-column-end: 3
    }

    .de-lobby-2023 .de-lobby-navigation #lobby-item-8 {
        grid-row-start: 3;
        grid-column-start: 4;
        grid-column-end: 4
    }

    .de-lobby-2023 .de-lobby-navigation .lobby-grid-ending {
        grid-row-start: 4;
        grid-column-start: 1;
        grid-column-end: 5
    }
}

@media only screen and (min-width: 1024px) {
    .de-lobby-2023 .de-lobby-navigation {
        grid-template-columns:1fr 1fr 1fr 1fr;
        grid-template-rows: auto auto auto auto;
        align-content: start;
        max-width: 1100px
    }

    .de-lobby-2023 .de-lobby-navigation .lobby-item-parent {
        grid-column: 1 / -1; /* Span full width */
        background-color: #f2f2f2;
        padding: 20px;
        text-align: center;
    }

    .de-lobby-2023 .de-lobby-navigation #lobby-item-3 {
        grid-row-start: 1;
        grid-column-start: 3;
        grid-column-end: 3
    }

    .de-lobby-2023 .de-lobby-navigation #lobby-item-4 {
        grid-row-start: 1;
        grid-column-start: 4;
        grid-column-end: 4
    }

    .de-lobby-2023 .de-lobby-navigation #lobby-item-5 {
        grid-row-start: 2;
        grid-column-start: 3;
        grid-column-end: 3
    }

    .de-lobby-2023 .de-lobby-navigation #lobby-item-6 {
        grid-row-start: 2;
        grid-column-start: 4;
        grid-column-end: 4
    }

    .de-lobby-2023 .de-lobby-navigation #lobby-item-7 {
        grid-row-start: 3;
        grid-column-start: 3;
        grid-column-end: 3
    }

    .de-lobby-2023 .de-lobby-navigation #lobby-item-8 {
        grid-row-start: 3;
        grid-column-start: 4;
        grid-column-end: 4
    }

    .de-lobby-2023 .de-lobby-navigation .lobby-grid-ending {
        grid-row-start: 4;
        grid-column-start: 1;
        grid-column-end: 5
    }
}

.de-lobby-2023 .de-lobby-navigation .lobby-bg-green {
    background-color: #009d76;
    color: #80da00
}

.de-lobby-2023 .de-lobby-navigation .lobby-bg-bluegreen {
    background-color: #008478;
    color: #80da00
}

.de-lobby-2023 .de-lobby-navigation .lobby-bg-lightgreen {
    background-color: #80da00;
    color: #008478
}

.de-lobby-2023 .de-lobby-navigation .lobby-item-parent {
    background: linear-gradient(60deg,#006747,#00996c)
}

.de-lobby-2023 .de-lobby-navigation .lobby-item {
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    transition: box-shadow .3s
}

.de-lobby-2023 .de-lobby-navigation .lobby-item:hover {
    box-shadow: inset 0 0 10px 2px rgba(0,0,0,.47)
}

.de-lobby-2023 .de-lobby-navigation .lobby-arrow {
    width: 70px;
    overflow: hidden
}

.de-lobby-2023 .de-lobby-navigation .lobby-arrow.hidden {
    opacity: 0
}

.de-lobby-2023 .de-lobby-navigation .lobby-text-container {
    text-align: center
}

.de-lobby-2023 .de-lobby-navigation .lobby-nav-title {
    font-size: 2rem;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    line-height: 1
}

.de-lobby-2023 .de-lobby-navigation .lobby-nav-title b,.de-lobby-2023 .de-lobby-navigation .lobby-nav-title strong {
    font-weight: 500
}

.de-lobby-2023 .de-lobby-navigation .lobby-nav-subtitle {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    padding-top: .5rem
}

.de-lobby-2023 .de-lobby-navigation .lobby-nav-subtitle b,.de-lobby-2023 .de-lobby-navigation .lobby-nav-subtitle strong {
    font-weight: 400
}

.de-lobby-2023 .de-lobby-navigation .lobby-grid-ending {
    height: 2rem;
    border: .5rem solid #fff;
    background: #018477;
    background: linear-gradient(90deg,#018477,#3aab42 25%,#6bcc14 50%,#6bcc14 75%,#018477)
}

.de-lobby-2023 .de-lobby-navigation .item-2-by-1 {
    aspect-ratio: 2/1
}

.de-lobby-2023 .de-lobby-navigation .item-1-by-1 {
    aspect-ratio: 1/1;
    border: .5rem solid #fff
}

@media only screen and (max-width: 767px) {
    .de-lobby-2023 .de-lobby-navigation .lobby-item-parent {
        grid-column-start:1;
        grid-column-end: 3
    }

    .de-lobby-2023 .de-lobby-navigation .lobby-nav-title {
        font-size: 1.2rem
    }

    .de-lobby-2023 .de-lobby-navigation .lobby-grid-ending {
        grid-column-start: 1;
        grid-column-end: 3
    }
}

.first {
  display: block;
}

.second {
  display: none;
}
