html {
    background-color: white;
    min-height: 100%;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: 'Roboto', -apple-system, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: 'AlternateGotNo2D';
    src: local('AlternateGotNo2D'), url(../webfonts/AlternateGotNo2D.woff2) format('woff2');
}

@font-face {
    font-family: 'Font Awesome Regular';
    src: url('../webfonts/fa-regular-400.eot');
    src: local('Font Awesome Regular'),
    url('../webfonts/fa-regular-400.woff2') format('woff2'),
    url('../webfonts/fa-regular-400.ttf') format('truetype'),
    url('../webfonts/fa-regular-400.woff') format('woff');
}

div {
    box-sizing: border-box;
}

h1,
h2,
h3,
p {
    margin: 0;
    padding: 0;
}

h1 {
    font-family: 'AlternateGotNo2D', sans-serif;
    font-size: 4rem;
    text-transform: uppercase;
    font-weight: normal;
    display: flex;
    align-items: center;
    color: #009b77;
}

h2 {
    font-family: 'AlternateGotNo2D', sans-serif;
    font-size: 3rem;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    color: #009b77;
    margin-bottom: 0.5rem;
}

h3 {
    font-family: 'AlternateGotNo2D', sans-serif;
    font-size: 1rem;
}

p, ul, input, select, option, button {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    line-height: 1.4;
    color: #727272;
}

a {
    color: #796e65;
    text-decoration: none;
}

a:hover {
    color: #009b77;
}
