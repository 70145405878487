.page-wrapper {
    width: 100%;
    max-width: 1900px;
    margin-bottom: 0!important;
    height: 100%;
    margin-left: auto;
    z-index: 10;
    margin-right: auto;
    display: flex;
    position: relative;
    justify-content: center
}

.page-wrapper-inner {
    display: grid;
    gap: 0;
    z-index: 10;
    align-content: start;
    width: 100%;
    grid-template-columns: 1fr;
    position: relative
}

@media only screen and (min-width: 768px) {
    .page-wrapper-inner {
        grid-template-columns:1fr 1fr 1fr 1fr;
        grid-template-rows: auto auto auto auto;
        align-content: start;
        max-width: 100%;
        margin-top: 4rem
    }

    .page-wrapper-inner .item-1-by-1 {
        aspect-ratio: 1/1;
        border: .5rem solid #fff
    }
}

@media only screen and (min-width: 1024px) {
    .page-wrapper-inner {
        grid-template-columns:1fr 1fr 1fr 1fr;
        grid-template-rows: auto auto auto auto;
        align-content: start;
        max-width: 1100px
    }
}

.page-wrapper-inner .lobby-item-parent {
    background: linear-gradient(60deg,#006747,#00996c);
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2
}

@media only screen and (max-width: 767px) {
    .page-wrapper-inner .lobby-item-parent {
        display:none
    }

    .page-wrapper-inner .lobby-item {
        padding-top: 2rem;
        padding-bottom: 2rem
    }
}

.page-wrapper-inner .lobby-item-inner {
    padding: 1rem 4rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    z-index: 10;
    position: relative
}

.page-wrapper-inner a.lobby-item-inner {
    padding: 2.7rem 6.1rem 1rem 4.3rem;
    transition: box-shadow .3s
}

.page-wrapper-inner a.lobby-item-inner:hover {
    text-decoration: none;
    box-shadow: inset 0 0 10px 2px rgba(0,0,0,.47)
}

.page-wrapper-inner .item-2-by-1 {
    aspect-ratio: 2/1
}

/* Disappear for larger devices (tablet and above) */
@media only screen and (max-width: 768px) {
    .mobile-only {
        display: none; /* Hide on screens 768px and smaller */
    }
}

@media only screen and (max-width: 992px) {
    .mobile-md-only {
        display: none; /* Hide on screens 992px and smaller */
    }
}

.mobile-md-only {
    background-color: transparent;
}

.page-content-outter {
    display: flex;
    position: absolute;
    z-index: 10;
    top: 5rem;
    width: 100vw;
    margin: 0rem;
    left: 0rem;
    bottom: 1rem;
    justify-content: center;
}


@media only screen and (min-width: 768px) {
    .page-content-outter .spacer.item-4-by-1 {
        aspect-ratio:4/1;
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 1;
        grid-row-end: 2;
        position: relative;
        pointer-events: none;
        background: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7)
    }
}

@media only screen and (max-width: 768px) {
    .page-content-outter {
        width: 72vw !important;
        left: 13vw !important;
        position: absolute;
        margin: auto;
    }
}

@media only screen and (max-width: 992px) {
    .page-content-outter {
        width: 96vw;
        left: 1rem;
        right: 0rem;
    }
}

.page-content-outter .page-content-inner {
    margin-top: .5rem;
    margin-bottom: 2rem;
    display: flex;
    background-color: #fff;
    box-shadow: 0 0 10px 2px rgba(0,0,0,.47);
    position: relative;
    padding-bottom: 0;
    width: 843px;
}

@media only screen and (min-width: 768px) {
    .page-content-outter .page-content-inner {
        grid-template-columns:1fr 3fr;
        grid-template-rows: 1fr auto;
        margin-left: 4rem;
        margin-right: 4rem
    }
}

@media screen and (max-width: 768px) {
    .page-content-outter.media-hub {
        display: flex;
        position: absolute;
        top: 10rem;
        width: auto;
        left: 0rem;
        max-height: 80vh;
        margin-left: 10px;
    }
    .page-content-outter.media-hub .page-content-inner {
        flex-direction: row;
    }
    
}

.page-content-outter .page-content-inner .lobby-item {
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly
}

.page-content-outter .page-content-inner .lobby-bg-green {
    background-color: #009d76;
    color: #80da00;
    border: .5rem solid #009d76
}

.page-content-outter .page-content-inner .lobby-bg-bluegreen {
    background-color: #008478;
    color: #80da00;
    border: .5rem solid #008478
}

.page-content-outter .page-content-inner .lobby-bg-lightgreen {
    background-color: #80da00;
    color: #008478;
    border: .5rem solid #80da00
}

.page-content-outter .page-content-inner .lobby-arrow {
    width: 70px;
    overflow: hidden
}

.page-content-outter .page-content-inner .lobby-arrow.hidden {
    opacity: 0
}

.page-content-outter .page-content-inner .lobby-nav-title {
    font-size: 2rem
}

.page-content-outter .page-content-inner .lobby-nav-title b,.page-content-outter .page-content-inner .lobby-nav-title strong {
    font-weight: 400
}

.page-content-outter .page-content-inner div.content-wrapper {
    padding: 2rem
}

.page-content-outter .page-content-inner .page-close-wrapper {
    position: absolute;
    top: .5rem;
    right: 1rem
}

.page-content-outter .page-content-inner .page-close-wrapper a.page-close-link {
    color: #000;
    line-height: 1
}

.page-content-outter .page-content-inner .page-close-wrapper a.page-close-link:hover {
    opacity: .8
}

.page-innovation div.content-wrapper {
    padding: 0!important
}

.page-agenda .page-content-inner div.content-wrapper {
    padding: 0
}

.page-agenda .page-content-inner div.content-wrapper .nav-tabs {
    border-bottom: 1rem solid #fff
}

.page-agenda .page-content-inner div.content-wrapper .nav-tabs .nav-link {
    display: none;
    margin-bottom: 0;
    border: 2px solid transparent;
    text-transform: uppercase;
    color: #008478
}

.page-agenda .page-content-inner div.content-wrapper .nav-tabs .nav-item.show .nav-link,.page-agenda .page-content-inner div.content-wrapper .nav-tabs .nav-link.active,.page-agenda .page-content-inner div.content-wrapper .nav-tabs .nav-link:hover {
    color: #80da00;
    background-color: #008478;
    border-color: #008478
}

.lobby-nav .lobby-nav-item {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #fff;
    font-size: 1.5vw
}

@media only screen and (min-width: 1900px) {
    .lobby-nav .lobby-nav-item {
        font-size:1.7rem
    }
}

.lobby-nav .lobby-nav-item .background {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    transition: transform .2s;
    transform: translate(-50%,-50%);
    pointer-events: none
}

.lobby-nav .lobby-nav-item .typo {
    position: relative;
    text-shadow: 0 0 10px rgba(0,0,0,.4);
    text-align: right
}

.lobby-nav .lobby-nav-item-0:active,.lobby-nav .lobby-nav-item-0:hover,.lobby-nav .lobby-nav-item-1:active,.lobby-nav .lobby-nav-item-1:hover,.lobby-nav .lobby-nav-item-2:active,.lobby-nav .lobby-nav-item-2:hover,.lobby-nav .lobby-nav-item-3:active,.lobby-nav .lobby-nav-item-3:hover {
    z-index: 10;
    text-decoration: none
}

.lobby-nav .lobby-nav-item-0:active .background,.lobby-nav .lobby-nav-item-0:hover .background,.lobby-nav .lobby-nav-item-1:active .background,.lobby-nav .lobby-nav-item-1:hover .background,.lobby-nav .lobby-nav-item-2:active .background,.lobby-nav .lobby-nav-item-2:hover .background,.lobby-nav .lobby-nav-item-3:active .background,.lobby-nav .lobby-nav-item-3:hover .background {
    transition: transform .3s;
    transform: translate(-50%,-50%) scale(1.1)
}

.lobby-nav .lobby-nav-item-0:active.lobby-nav-item-disabled,.lobby-nav .lobby-nav-item-0:hover.lobby-nav-item-disabled,.lobby-nav .lobby-nav-item-1:active.lobby-nav-item-disabled,.lobby-nav .lobby-nav-item-1:hover.lobby-nav-item-disabled,.lobby-nav .lobby-nav-item-2:active.lobby-nav-item-disabled,.lobby-nav .lobby-nav-item-2:hover.lobby-nav-item-disabled,.lobby-nav .lobby-nav-item-3:active.lobby-nav-item-disabled,.lobby-nav .lobby-nav-item-3:hover.lobby-nav-item-disabled {
    z-index: 1;
    pointer-events: none
}

.lobby-nav .lobby-nav-item-0:active.lobby-nav-item-disabled .background,.lobby-nav .lobby-nav-item-0:hover.lobby-nav-item-disabled .background,.lobby-nav .lobby-nav-item-1:active.lobby-nav-item-disabled .background,.lobby-nav .lobby-nav-item-1:hover.lobby-nav-item-disabled .background,.lobby-nav .lobby-nav-item-2:active.lobby-nav-item-disabled .background,.lobby-nav .lobby-nav-item-2:hover.lobby-nav-item-disabled .background,.lobby-nav .lobby-nav-item-3:active.lobby-nav-item-disabled .background,.lobby-nav .lobby-nav-item-3:hover.lobby-nav-item-disabled .background {
    transition: transform .3s;
    transform: translate(-50%,-50%) scale(1)
}

.lobby-nav .lobby-nav-item-0 {
    z-index: 5;
    width: 15%;
    height: 24%;
    left: 51.5%;
    top: 12%;
    padding-right: 4%
}

.lobby-nav .lobby-nav-item-1 {
    z-index: 4;
    width: 22%;
    height: 35%;
    left: 67.3%;
    top: 16.3%;
    padding-right: 6%;
    padding-bottom: 3%
}

.lobby-nav .lobby-nav-item-4 {
    left: 29%;
    top: 78%
}

.lobby-nav .lobby-nav-item-5 {
    left: 80%;
    top: 79%
}

.container-glsexcellence-wrapper {
    display: grid;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-columns: 1fr 1fr
}

@media only screen and (min-width: 768px) {
    .container-glsexcellence-wrapper {
        grid-template-columns:1fr 1fr 1fr 1fr
    }
}

.container-glsexcellence-wrapper .glsexcellence-item-outter a.glsexcellence-item {
    display: flex;
    transition: opacity .3s,border-color .3s
}

.container-glsexcellence-wrapper .glsexcellence-item-outter a.glsexcellence-item:hover {
    opacity: .7
}

.container-glsexcellence-wrapper-items {
    display: grid;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-columns: 1fr 3fr
}

.container-glsexcellence-wrapper-items .glsexcellence-item-outter a.glsexcellence-item {
    display: flex;
    transition: opacity .3s,border-color .3s
}

.container-glsexcellence-wrapper-items .glsexcellence-item-outter a.glsexcellence-item:hover {
    opacity: .7
}
